var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"grey lighten-5 rounded-lg px-0 px-md-16 py-8"},[_c('v-stepper',{staticClass:"elevation-0 grey lighten-5",model:{value:(_vm.el),callback:function ($$v) {_vm.el=$$v},expression:"el"}},[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center primary--text mb-3 text-h4 font-weight-medium"},[_vm._v("Create One Time Fixed Savings")]),_c('div',{staticClass:"text-center"},[_vm._v("Reach your personal goals much faster.")]),_c('div',{staticClass:"mt-6"}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Savings Type "+_vm._s(_vm.form.type))]),_c('v-select',{attrs:{"placeholder":"Select","required":"","rules":[_vm.rules.required],"items":[ // { text: 'Recurrent Savings (Normal Interest)', value: 'Recurrent' },
                         //   { text: 'Target Savings (Normal Interest)', value: 'Target' },
                            { text: 'Fixed Savings (High Interest)', value: 'Fixed' } ],"outlined":"","solo":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Title of Savings")]),_c('v-text-field',{attrs:{"placeholder":"Housing/Accomodation","required":"","rules":[_vm.rules.required],"outlined":"","solo":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Description (optional)")]),_c('v-text-field',{attrs:{"placeholder":"Description","outlined":"","solo":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Set overall amount to save")]),_c('v-text-field',{attrs:{"type":"number","placeholder":"E.g  50,000","required":"","rules":[_vm.rules.required, _vm.rules.min],"outlined":"","solo":""},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.type != 'Fixed'),expression:"form.type != 'Fixed'"}],staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Select cycle/frequency")]),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.type != 'Fixed'),expression:"form.type != 'Fixed'"}],attrs:{"placeholder":"Select","items":['Daily', 'Weekly', 'Monthly'],"outlined":"","solo":""},model:{value:(_vm.form.cycle),callback:function ($$v) {_vm.$set(_vm.form, "cycle", $$v)},expression:"form.cycle"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Duration")]),_c('v-select',{attrs:{"placeholder":"Select","required":"","rules":[_vm.rules.required],"items":[
                   // { text: '1 Month', value: 1 },
                  //  { text: '2 Months', value: 2 },
                    { text: '3 Months - 4% Interest', value: 3 },
                //    { text: '4 Months', value: 4 },
                //    { text: '5 Months', value: 5 },
                    { text: '6 Months - 8% Interest', value: 6 },
              //      { text: '7 Months', value: 7 },
             //       { text: '8 Months', value: 8 },
                    { text: '9 Months - 12% Interest', value: 9 },
               //     { text: '10 Months', value: 10 },
             //       { text: '11 Months', value: 11 },
                    { text: '12 Months - 16% Interest', value: 12 } ],"outlined":"","solo":""},model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}}),_c('div',[_c('v-btn',{staticClass:"primary",attrs:{"block":"","x-large":"","text":""},on:{"click":function($event){_vm.$refs.form.validate() ? (_vm.el = 2) : null}}},[_vm._v("Continue")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-form',{ref:"form2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center text-h4 primary--text mb-6"},[_vm._v("Finish setting up savings")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-card',{staticClass:"primary pa-4",attrs:{"width":"400"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('img',{attrs:{"src":require("@/static/assets/save-icon.svg"),"alt":""}})]),_c('v-col',[_c('small',{staticClass:"white--text",staticStyle:{"font-size":"12px"}},[_vm._v("Reach ₦"+_vm._s(_vm._f("formatPrice")(_vm.form.amount || 0))+" in your \""+_vm._s(_vm.form.name)+"\" savings on your personal savings. target.")]),_c('p',{staticClass:"white--text text-h5"},[_vm._v(" ₦"+_vm._s(_vm._f("formatPrice")(_vm.form.amount || 0))+" ")])])],1)],1)],1),_c('div',{staticClass:"mt-6"}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.type != 'Fixed'),expression:"form.type != 'Fixed'"}],attrs:{"placeholder":"N 500 Daily","type":"number","readonly":"","rules":_vm.requiredIfFixed,"outlined":"","solo":""},model:{value:(_vm.overallAmount),callback:function ($$v) {_vm.overallAmount=$$v},expression:"overallAmount"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Set Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"append-icon":"event_note","outlined":"","value":_vm.form.start_date,"placeholder":"Select start date","readonly":"","solo":""}},on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"light":"","min":_vm.nowDate()},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Set Withdrawal Date")]),_c('v-menu',{attrs:{"disabled":"","close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"append-icon":"event_note","outlined":"","value":_vm.form.end_date,"placeholder":"Select withdrawal date","readonly":"","solo":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"light":"","readonly":"","min":_vm.startDate(),"max":_vm.getEndDate()},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1),_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"required":"","rules":[
                    function (v) { return !!v || 'You must accept our terms and conditions'; } ],"label":"By clicking here you accept our terms and conditions"},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}}),_c('v-btn',{staticClass:"primary mb-3",attrs:{"block":"","x-large":"","text":"","loading":_vm.loading},on:{"click":function($event){_vm.$refs.form2.validate() ? _vm.createSavings() : null}}},[_vm._v("Create Personal Savings")]),_c('v-btn',{staticClass:"mt-3",attrs:{"x-large":"","text":"","block":""},on:{"click":function($event){_vm.el = 1}}},[_vm._v(" Back ")])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }