<template>
  <div>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <div class="grey lighten-5 rounded-lg px-0 px-md-16 py-8">
            <v-stepper v-model="el" class="elevation-0 grey lighten-5">
              <v-stepper-content step="1">
                <v-form v-model="valid" lazy-validation ref="form">
                  <div class="text-center primary--text mb-3 text-h4 font-weight-medium">Create One Time Fixed Savings</div>
                  <div class="text-center">Reach your personal goals much faster.</div>
                  <div class="mt-6"></div>
                  <div class="text-h6 mb-2 primary--text"><!--Personal-->Savings Type {{form.type}}</div>
                  <v-select
                    placeholder="Select"
                    required
                    v-model="form.type"
                    :rules="[rules.required]"
                    :items="[ // { text: 'Recurrent Savings (Normal Interest)', value: 'Recurrent' },
                           //   { text: 'Target Savings (Normal Interest)', value: 'Target' },
                              { text: 'Fixed Savings (High Interest)', value: 'Fixed' },
                            //  { text: 'Flex Savings (Normal Interest)', value: 'Flex' }
                            ]"
                    outlined solo
                  ></v-select>
                  <div class="text-h6 mb-2 primary--text">Title of Savings</div>
                  <v-text-field
                    placeholder="Housing/Accomodation"
                    v-model="form.name"
                    required
                    :rules="[rules.required]"
                    outlined solo
                  ></v-text-field>
                  <div class="text-h6 mb-2 primary--text">Description (optional)</div>
                  <v-text-field
                    placeholder="Description"
                    v-model="form.description"
                    outlined solo
                  ></v-text-field>
                  <div class="text-h6 mb-2 primary--text">Set overall amount to save</div>
                  <v-text-field type="number"
                    placeholder="E.g  50,000"
                    v-model="form.amount"
                    required
                    :rules="[rules.required, rules.min]"
                    outlined solo
                  ></v-text-field>
                  <div v-show="form.type != 'Fixed'" class="text-h6 mb-2 primary--text">Select cycle/frequency</div>
                  <v-select
                    placeholder="Select"
                    v-model="form.cycle"
                    :items="['Daily', 'Weekly', 'Monthly']"
                    outlined solo v-show="form.type != 'Fixed'" 
                  ></v-select>
                  <div class="text-h6 mb-2 primary--text">Duration</div>
                  <v-select
                    placeholder="Select"
                    required
                    v-model="form.duration"
                    :rules="[rules.required]"
                    :items="[
                     // { text: '1 Month', value: 1 },
                    //  { text: '2 Months', value: 2 },
                      { text: '3 Months - 4% Interest', value: 3 },
                  //    { text: '4 Months', value: 4 },
                  //    { text: '5 Months', value: 5 },
                      { text: '6 Months - 8% Interest', value: 6 },
                //      { text: '7 Months', value: 7 },
               //       { text: '8 Months', value: 8 },
                      { text: '9 Months - 12% Interest', value: 9 },
                 //     { text: '10 Months', value: 10 },
               //       { text: '11 Months', value: 11 },
                      { text: '12 Months - 16% Interest', value: 12 },
                    ]"
                    outlined solo
                  ></v-select>
                  <div>
                    <v-btn
                      class="primary"
                      block
                      x-large
                      text
                      @click="$refs.form.validate() ? (el = 2) : null"
                      >Continue</v-btn
                    >
                  </div>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-form v-model="valid" lazy-validation ref="form2">
                  <div class="text-center text-h4 primary--text mb-6">Finish setting up savings</div>
                  <div style="display: flex; align-items: center; justify-content: center">
                    <v-card width="400" class="primary pa-4">
                      <v-row>
                        <v-col cols="2">
                          <img src="@/static/assets/save-icon.svg" alt="" />
                        </v-col>
                        <v-col>
                          <small class="white--text" style="font-size: 12px"
                            >Reach ₦{{form.amount || 0 | formatPrice}} in your "{{form.name}}" savings on your personal savings.
                            target.</small>
                            
                          <p class="white--text text-h5">
                            &#8358;{{form.amount || 0 | formatPrice}}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>

                  <div class="mt-6"></div>
                  <!--div v-show="form.type != 'Fixed'"  class="text-h6 mb-2 primary--text">Overall amount to save {{ form.cycle}}</div-->
                  <v-text-field
                    placeholder="N 500 Daily" type="number"
                    v-model="overallAmount"
                    readonly
                    :rules="requiredIfFixed"
                    outlined solo v-show="form.type != 'Fixed'" 
                  ></v-text-field>
                  <div class="text-h6 mb-2 primary--text">Set Start Date</div>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="event_note"
                        outlined
                        :value="form.start_date"
                        placeholder="Select start date"
                        readonly solo
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      light
                      v-model="form.start_date"
                      :min="nowDate()"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <div class="text-h6 mb-2 primary--text">Set Withdrawal Date</div>
                  <v-menu
                    v-model="menu" disabled
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="event_note"
                        outlined
                        :value="form.end_date"
                        placeholder="Select withdrawal date"
                        readonly solo
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      light readonly
                      v-model="form.end_date"
                      :min="startDate()" 
                      :max="getEndDate()"
                      @change="menu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-checkbox
                    v-model="form.terms"
                    required
                    :rules="[
                      (v) => !!v || 'You must accept our terms and conditions',
                    ]"
                    label="By clicking here you accept our terms and conditions"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                  <v-btn
                    class="primary mb-3"
                    block
                    x-large
                    text
                    :loading="loading"
                    @click="$refs.form2.validate() ? createSavings() : null"
                    >Create Personal Savings</v-btn
                  >
                  <v-btn x-large text block class="mt-3" @click="el = 1"> Back </v-btn>
                </v-form>
              </v-stepper-content>
            </v-stepper>
          </div>
        </v-col>
      </v-row>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      el: 1,
      menu2: false,
      menu1: false,
      menu: false,
      valid: true,
      loading: false,
      form: {},
      rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 100 || "Savings amount can't go below ₦100"
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    
    overallAmount: function () {
      const a_month = 31 // days
      const duration = this.form.duration * a_month
      // get amount to save 
      if (this.form.cycle == 'Daily') {
        const amt = Math.round((this.form.amount / duration) * 1)
        return amt.toFixed(2)
      } else if (this.form.cycle == 'Weekly') {
        const amt = Math.round((this.form.amount / duration) * 7)
        return amt.toFixed(2) 
      } else if (this.form.cycle == 'Monthly') {
        const amt = Math.round((this.form.amount / duration) * 31)
        return amt.toFixed(2) 
      }
    },
    requiredIfFixed: function() {
      return [
        (v) => {
          if(this.form.type != 'Fixed' && !v) {
            return 'This field is required'
          }
          return true
        }
      ]
    }
  },

  mounted(){
    this.form.userId = this.user.id
  },
  
  methods: {
    async createSavings() {
      this.loading = true
      this.form.overall_amount = this.overallAmount
      /*if(!this.form.terms_recurrent) {
        this.$toast.error('You have to agree to Terms and Conditions')
        this.loading = false
      }*/
      if(this.form.type == 'Fixed') {
        this.form.cycle = "Monthly"
        const duration = this.form.duration * 31
        const amt = Math.round((this.form.amount / duration) * 31)
        this.form.overall_amount = amt.toFixed(2)
      }
      if(this.form.type != 'Fixed' && !this.form.cycle){
        this.$toast.error('Select cycle/frequency for this savings')
        this.loading = false
      } else {
        try {
          let res = await this.$store.dispatch('savings/createPersonal', this.form)
          this.$toast.success(res.message)
          this.loading = false
          this.$refs.form.reset()
          this.form = {}
          this.form.userId = this.user.id
          this.el = 1
        } catch (err) {
          this.$toast.error(err.response.data.message)
          this.loading = false
        }
      }
    },
    nowDate() {
      return new Date().toISOString().slice(0,10)
    },
    getEndDate() {
      if (this.form.start_date != undefined && this.form.duration != undefined) {
        const startDate = this.form.start_date.split('-')
        const startDateParse = startDate.map((i) => Number(i))
        const endDate = new Date(startDateParse[0], startDateParse[1] - 1, startDate[2])
        const day = 31 * parseInt(this.form.duration)
        endDate.setDate(endDate.getDate() + day)
        this.form.end_date = endDate.toISOString().slice(0,10)
        return endDate.toISOString().slice(0,10)
      }
    },
    startDate() {
      if(this.form.start_date != undefined) {
        return this.form.start_date
      }
    }
  },
}
</script>
<style lang="scss" scoped></style>
