var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-5 rounded-lg px-0 px-md-16 py-8"},[_c('v-stepper',{staticClass:"elevation-0 grey lighten-5",model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center primary--text mb-3 text-h4 font-weight-medium"},[_vm._v("Set Up Agent Savings Account")]),_c('div',{staticClass:"text-center"},[_vm._v("Reach your personal goals much faster.")]),_c('div',{staticClass:"mt-6"}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Title of Agent Savings")]),_c('v-text-field',{attrs:{"placeholder":"House Hold Items","required":"","rules":[function (v) { return !!v || 'This field is required'; }],"outlined":"","solo":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Description (Optional)")]),_c('v-text-field',{attrs:{"placeholder":"Description","outlined":"","solo":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Select Choice Setting")]),_c('v-select',{attrs:{"placeholder":"Select","required":"","rules":[function (v) { return !!v || 'This field is required'; }],"items":[
              { text: 'Admin Setup', value: 1 },
              { text: 'Member', value: 0 } ],"outlined":"","solo":""},model:{value:(_vm.form.admin_setup),callback:function ($$v) {_vm.$set(_vm.form, "admin_setup", $$v)},expression:"form.admin_setup"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Set Contribution amount")]),_c('v-text-field',{attrs:{"type":"number","placeholder":"E.g  50,000","required":"","rules":[function (v) { return !!v || 'This field is required'; }],"outlined":"","solo":""},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("How do you prefer to save? (frequency)")]),_c('v-select',{attrs:{"placeholder":"Select","required":"","rules":[function (v) { return !!v || 'This field is required'; }],"items":['Daily', 'Weekly', 'Monthly'],"outlined":"","solo":""},model:{value:(_vm.form.cycle),callback:function ($$v) {_vm.$set(_vm.form, "cycle", $$v)},expression:"form.cycle"}}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Duration")]),_c('v-select',{attrs:{"placeholder":"Select","required":"","rules":[function (v) { return !!v || 'This field is required'; }],"items":[
              { text: '1 Month', value: 1 },
              { text: '2 Months', value: 2 },
              { text: '3 Months', value: 3 },
              { text: '4 Months', value: 4 },
              { text: '5 Months', value: 5 },
              { text: '6 Months', value: 6 },
              { text: '7 Months', value: 7 },
              { text: '8 Months', value: 8 },
              { text: '9 Months', value: 9 },
              { text: '10 Months', value: 10 },
              { text: '11 Months', value: 11 },
              { text: '12 Months', value: 12 } ],"outlined":"","solo":""},model:{value:(_vm.form.duration),callback:function ($$v) {_vm.$set(_vm.form, "duration", $$v)},expression:"form.duration"}}),_c('v-btn',{staticClass:"primary",attrs:{"block":"","x-large":"","text":""},on:{"click":function($event){_vm.$refs.form.validate() ? (_vm.e1 = 2) : null}}},[_vm._v("Continue")])],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-form',{ref:"form2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center primary--text mb-3 text-h4 font-weight-medium"},[_vm._v("Finish setting up")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-card',{staticClass:"primary pa-4",attrs:{"width":"350"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('img',{attrs:{"src":require("@/static/assets/save-icon.svg"),"alt":""}})]),_c('v-col',[_c('p',{staticClass:"white--text",staticStyle:{"font-size":"18px","line-height":"15px"}},[_vm._v(" ₦"+_vm._s(_vm._f("formatPrice")(_vm.form.amount || 0))+" ")]),_c('small',{staticClass:"white--text",staticStyle:{"font-size":"12px"}},[_vm._v("The total amount of "+_vm._s(_vm._f("formatPrice")((parseInt(_vm.form.amount) * _vm.form.num_of_members)))+" contributed will go to one person")])])],1)],1)],1),_c('div',{staticClass:"mt-6"}),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Set Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"append-icon":"event_note","outlined":"","solo":"","value":_vm.form.start_date,"placeholder":"Select start date","readonly":""}},on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"light":""},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Set Withdrawal /End Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"append-icon":"event_note","outlined":"","solo":"","value":_vm.form.end_date,"placeholder":"Select withdrawal date","readonly":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"light":""},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1),_c('div',{staticClass:"text-h6 mb-2 primary--text"},[_vm._v("Maximum Number")]),_c('v-select',{attrs:{"placeholder":"Select funding source","required":"","rules":[function (v) { return !!v || 'This field is required'; }],"items":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10],"outlined":"","solo":""},model:{value:(_vm.form.num_of_members),callback:function ($$v) {_vm.$set(_vm.form, "num_of_members", $$v)},expression:"form.num_of_members"}}),_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"required":"","rules":[
              function (v) { return !!v || 'You must accept our terms and conditions'; } ],"label":"By clicking here you accept our terms and conditions"},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}}),_c('v-btn',{staticClass:"primary",attrs:{"block":"","x-large":"","text":"","loading":_vm.loading},on:{"click":function($event){_vm.$refs.form2.validate() ? _vm.createSavings() : null}}},[_vm._v("Create Agent Savings")]),_c('v-btn',{staticClass:"mt-3",attrs:{"x-large":"","text":"","block":""},on:{"click":function($event){_vm.e1 = 1}}},[_vm._v("Back ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }