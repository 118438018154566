<template>
    <div>
        <v-container>
            <v-progress-circular indeterminate color="grey" :size="20" :width="2"></v-progress-circular>
        </v-container>
    </div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
}
</script>