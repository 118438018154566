<template>
  <div>
    <div class="grey lighten-5 rounded-lg px-0 px-md-16 py-8">
      <v-stepper v-model="e1" class="elevation-0 grey lighten-5">
        <v-stepper-content step="1">
          <v-form v-model="valid" lazy-validation ref="form">
            <div class="text-center primary--text mb-3 text-h4 font-weight-medium">Set Up Agent Savings Account</div>
            <div class="text-center">Reach your personal goals much faster.</div>
            <div class="mt-6"></div>
            <div class="text-h6 mb-2 primary--text">Title of Agent Savings</div>
            <v-text-field
              placeholder="House Hold Items"
              v-model="form.name"
              required
              :rules="[(v) => !!v || 'This field is required']"
              outlined solo
            ></v-text-field>
            <div class="text-h6 mb-2 primary--text">Description (Optional)</div>
            <v-text-field
              placeholder="Description"
              v-model="form.description"
              outlined solo
            ></v-text-field>
            <div class="text-h6 mb-2 primary--text">Select Choice Setting</div>
            <v-select
              placeholder="Select"
              required
              v-model="form.admin_setup"
              :rules="[(v) => !!v || 'This field is required']"
              :items="[
                { text: 'Admin Setup', value: 1 },
                { text: 'Member', value: 0 },
              ]"
              outlined solo
            ></v-select>
            <div class="text-h6 mb-2 primary--text">Set Contribution amount</div>
            <v-text-field
              type="number"
              placeholder="E.g  50,000"
              v-model="form.amount"
              required
              :rules="[(v) => !!v || 'This field is required']"
              outlined solo
            ></v-text-field>
            <div class="text-h6 mb-2 primary--text">How do you prefer to save? (frequency)</div>
            <v-select
              placeholder="Select"
              required
              v-model="form.cycle"
              :rules="[(v) => !!v || 'This field is required']"
              :items="['Daily', 'Weekly', 'Monthly']"
              outlined solo
            ></v-select>
            <div class="text-h6 mb-2 primary--text">Duration</div>
            <v-select
              placeholder="Select"
              required
              v-model="form.duration"
              :rules="[(v) => !!v || 'This field is required']"
              :items="[
                { text: '1 Month', value: 1 },
                { text: '2 Months', value: 2 },
                { text: '3 Months', value: 3 },
                { text: '4 Months', value: 4 },
                { text: '5 Months', value: 5 },
                { text: '6 Months', value: 6 },
                { text: '7 Months', value: 7 },
                { text: '8 Months', value: 8 },
                { text: '9 Months', value: 9 },
                { text: '10 Months', value: 10 },
                { text: '11 Months', value: 11 },
                { text: '12 Months', value: 12 },
              ]"
              outlined solo
            ></v-select>
            <v-btn
              class="primary"
              block
              x-large
              text
              @click="$refs.form.validate() ? (e1 = 2) : null"
              >Continue</v-btn
            >
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-form v-model="valid" lazy-validation ref="form2">
            <div class="text-center primary--text mb-3 text-h4 font-weight-medium">Finish setting up</div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <v-card width="350" class="primary pa-4">
                <v-row>
                  <v-col cols="2">
                    <img src="@/static/assets/save-icon.svg" alt="" />
                  </v-col>
                  <v-col>
                    <p
                      class="white--text"
                      style="font-size: 18px; line-height: 15px"
                    >
                      &#8358;{{ form.amount || 0 | formatPrice }}
                    </p>
                    <small class="white--text" style="font-size: 12px"
                      >The total amount of {{(parseInt(form.amount) * form.num_of_members) | formatPrice}} contributed will go to one
                      person</small
                    >
                  </v-col>
                </v-row>
              </v-card>
            </div>

            <div class="mt-6"></div>
            <!-- <div class="text-h6 mb-2 primary--text">Amount to save Monthly</div>
            <v-text-field
              placeholder="N 500 Daily" type="number"
              v-model="form.amount"
              required
              :rules="[(v) => !!v || 'This field is required']"
              outlined solo
            ></v-text-field> -->
            <!-- <div class="text-h6 mb-2 primary--text">Day of the month</div>
            <v-select
              placeholder=""
              required
              :rules="[(v) => !!v || 'This field is required']"
              :items="[
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
                '31',
              ]"
              outlined solo
            ></v-select> -->
            <!-- <div class="text-h6 mb-2 primary--text">Preferred Time</div>
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.time"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined solo
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu> -->

            <div class="text-h6 mb-2 primary--text">Set Start Date</div>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  append-icon="event_note"
                  outlined solo
                  :value="form.start_date"
                  placeholder="Select start date"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                light
                v-model="form.start_date"
                @change="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <div class="text-h6 mb-2 primary--text">Set Withdrawal /End Date</div>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  append-icon="event_note"
                  outlined solo
                  :value="form.end_date"
                  placeholder="Select withdrawal date"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                light
                v-model="form.end_date"
                @change="menu = false"
              ></v-date-picker>
            </v-menu>
            <div class="text-h6 mb-2 primary--text">Maximum Number</div>
            <v-select
              placeholder="Select funding source"
              required
              v-model="form.num_of_members"
              :rules="[(v) => !!v || 'This field is required']"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              outlined solo
            ></v-select>
            <v-checkbox
              v-model="form.terms"
              required
              :rules="[
                (v) => !!v || 'You must accept our terms and conditions',
              ]"
              label="By clicking here you accept our terms and conditions"
              class="ma-0 pa-0"
            ></v-checkbox>
            <v-btn
              class="primary"
              block
              x-large
              text
              :loading="loading"
              @click="$refs.form2.validate() ? createSavings() : null"
              >Create Agent Savings</v-btn
            >
            <v-btn x-large text block @click="e1 = 1" class="mt-3">Back </v-btn>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      e1: 1,
      menu2: false,
      menu1: false,
      menu: false,
      valid: true,
      loading: false,
      form: {},
      rules: {
        required: value => !!value || 'This field is required'
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    this.form.user_id = this.user.id
  },
  methods: {
    async createSavings() {
      this.loading = true
      try {
        let res = await this.$store.dispatch('savings/createAgent', this.form)
        this.$toast.success(res.message)
        this.loading = false
        this.$refs.form2.reset()
        this.form = {}
      } catch(err) {
        this.$toast.error(err.response.data.message)
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
