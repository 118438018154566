<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <div class="grey lighten-5 rounded-lg px-0 px-md-16 py-8">
          <v-stepper v-model="e1" class="elevation-0 grey lighten-5">
            <v-stepper-content step="1">
              <v-form v-model="valid" lazy-validation ref="form">
                <div class="text-center primary--text mb-3 text-h4 font-weight-medium">Set Up Group Savings</div>
                <div class="text-center">Reach your personal goals much faster.</div>
                <div class="mt-6"></div>
                <div class="text-h6 mb-2 primary--text">Title of group Savings</div>
                <v-text-field
                  placeholder="House Hold Items"
                  v-model="form.name"
                  required
                  :rules="[rules.required]"
                  outlined solo
                ></v-text-field>
                <div class="text-h6 mb-2 primary--text">Description (optional)</div>
                <v-text-field
                  placeholder="Description"
                  v-model="form.description"
                  outlined solo
                ></v-text-field>
                <div class="text-h6 mb-2 primary--text">Select group type</div>
                <v-select
                  placeholder="Select"
                  required
                  v-model="form.access"
                  :rules="[rules.required]"
                  :items="['Public', 'Private']"
                  outlined solo
                ></v-select>
                <div class="text-h6 mb-2 primary--text">Set overall savings target</div>
                <v-text-field type="number"
                  placeholder="E.g  50,000"
                  v-model="form.overall_amount"
                  required
                  :rules="[rules.required, rules.min]"
                  outlined solo
                ></v-text-field>
                <div class="text-h6 mb-2 primary--text">How do you prefer to save? (frequency)</div>
                <v-select
                  placeholder="Select"
                  required
                  v-model="form.cycle"
                  :rules="[rules.required]"
                  :items="['Daily', 'Weekly', 'Monthly']"
                  outlined solo
                ></v-select>
                <v-btn
                  class="primary"
                  block
                  x-large
                  text
                  @click="$refs.form.validate() ? (e1 = 2) : null"
                  >Continue</v-btn
                >
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form v-model="valid" lazy-validation ref="form2">
                <div class="text-center text-h4 primary--text mb-6">Finish setting up</div>
                <div style="display: flex; align-items: center; justify-content: center">
                  <v-card width="400" class="primary pa-4">
                    <v-row>
                      <v-col cols="2">
                        <img src="@/static/assets/save-icon.svg" alt="" />
                      </v-col>
                      <v-col>
                        <small class="white--text" style="font-size: 12px"
                          >You want to reach ₦{{form.overall_amount || 0 | formatPrice}} in your "{{form.name}}" savings on your group savings.</small>
                          
                        <p class="white--text text-h5">
                          &#8358;{{form.overall_amount || 0 | formatPrice}}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>

                <div class="mt-6"></div>
                <div class="text-h6 mb-2 primary--text">Amount to save {{ form.cycle }}</div>
                <v-text-field
                  placeholder="N 500 Daily" type="number"
                  v-model="form.amount"
                  required
                  :rules="[rules.required]"
                  outlined solo
                ></v-text-field>
                <div class="text-h6 mb-2 primary--text">Set Start Date</div>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      append-icon="event_note"
                      outlined solo
                      :value="form.start_date"
                      :min="nowDate()"
                      placeholder="Select start date"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    light
                    v-model="form.start_date"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <div class="text-h6 mb-2 primary--text">Withdrawal Date</div>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      append-icon="event_note"
                      outlined solo
                      :value="form.end_date"
                      :min="startDate()" 
                      placeholder="Select withdrawal date"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    light
                    v-model="form.end_date"
                    @change="menu = false"
                  ></v-date-picker>
                </v-menu>
                <div class="text-h6 mb-2 primary--text">Maximum members</div>
                <v-select
                  placeholder="Select funding source"
                  required v-model="form.num_of_members"
                  :rules="[rules.required]"
                  :items="[1,2,3,4,5,6,7,8,9,10]"
                  outlined solo
                ></v-select>
                <v-checkbox
                  v-model="form.terms"
                  required
                  :rules="[
                    (v) => !!v || 'You must accept our terms and conditions',
                  ]"
                  label="By clicking here you accept our terms and conditions"
                  class="ma-0 pa-0"
                ></v-checkbox>
                <v-btn
                  class="primary"
                  block
                  x-large
                  text
                  :loading="loading"
                  @click="$refs.form.validate() ? createSavings() : null"
                  >Create Group Savings</v-btn
                >
                <v-btn x-large text block class="mt-3" @click="e1 = 1"> Back </v-btn>
              </v-form>
            </v-stepper-content>
          </v-stepper>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      e1: 1,
      menu2: false,
      menu1: false,
      menu: false,
      valid: true,
      loading: false,
      form: {},
      rules: {
        required: value => !!value || 'This field is required',
        min: value => value >= 100 || "Savings amount can't go below ₦100"
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  mounted(){
    this.form.userId = this.user.id
  },
  methods: {
    async createSavings() {
      this.loading = true
      try {
        let res = await this.$store.dispatch('savings/createGroup', this.form)
        this.$toast.success(res.message)
        this.loading = false
        this.$refs.form.reset()
        this.form = {}
      } catch(err) {
        this.$toast.error(error.res.data.message)
        this.loading = false
      }
    },
    nowDate() {
      return new Date().toISOString().slice(0,10)
    },
    /*getEndDate() {
      if (this.form.start_date != undefined && this.form.duration != undefined) {
        const startDate = this.form.start_date.split('-')
        const startDateParse = startDate.map((i) => Number(i))
        const endDate = new Date(startDateParse[0], startDateParse[1] - 1, startDate[2])
        const day = 31 * parseInt(this.form.duration)
        endDate.setDate(endDate.getDate() + day)
        this.form.end_date = endDate.toISOString().slice(0,10)
        return endDate.toISOString().slice(0,10)
      }
    },*/
    startDate() {
      if(this.form.start_date != undefined) {
        return this.form.start_date
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
